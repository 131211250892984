import { useEffect, CSSProperties, useState, useCallback } from "react";
import { BsCart } from "react-icons/bs";
import { MdCheck, MdClose, MdError, MdPaid } from "react-icons/md";
import { FormControlLabel, InputLabel, Radio, RadioGroup, Slider, Switch } from "@mui/material";
import { Alert, Button, Checkbox, CircularProgress, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { SpacerV } from "./Spacer";
import { FlexColumn, FlexRow } from "./components/Flexbox";
import { preorderHoop, useAuth, validatePromotionCode } from "./db";
import { HoopModel, HoopOrder, PaymentMethods, ShippingLocations, getHoopShippingCost } from "./Order";
import debounce from "lodash/debounce";
import { useModal } from "./Modal";
import { Palette, styles } from "./style";
import { FormattedMessage, useIntl } from "react-intl";
import { SectionTitle } from "./components/SectionTitle";
import { LineSeparator } from "./components/LineSeparator";
import { BigActionButton } from "./BigActionButton";
import { Header } from "./Header";
import { useLanguageSelector } from "./intlMessages";
import { authUIConfig, getMyAuthUI } from "./authUI";
import { round1, round2 } from "./tools";

export const PaiementSuccess = () => {
  return (
    <>
      <Header alwaysVisible />
      <FlexColumn center style={{ maxWidth: 300, height: "100vh" }}>
        <img src="/logo_title.webp" style={{ width: 300 }} />
        <SpacerV size={100} />
        <MdPaid size={40} />
        <SpacerV size={20} />
        <div>
          <FormattedMessage
            defaultMessage="Votre paiement a bien été reçu, vous recevrez prochainement une
          confirmation par email."
          />
        </div>
        <SpacerV size={100} />
        <BigActionButton href="/">Retour au site</BigActionButton>
      </FlexColumn>
    </>
  );
};
export const PaiementCancelled = () => {
  return (
    <>
      <Header alwaysVisible />
      <FlexColumn center style={{ width: "100wv", height: "100vh" }}>
        <img src="/logo_title.webp" style={{ width: 300 }} />
        <MdError size={40} />
        <SpacerV size={20} />
        <div>
          <FormattedMessage defaultMessage="Votre paiement n'a pas abouti. Veuillez re-essayer" />
        </div>
        <SpacerV size={100} />
        <BigActionButton href="/">
          <FormattedMessage defaultMessage="Retour au site" />
        </BigActionButton>
      </FlexColumn>
    </>
  );
};

export const ShippingDetailModal = ({
  onSubmit,
  onClose,
}: {
  onSubmit: (address: string, phone: string) => void;
  onClose: () => void;
}) => {
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <FlexColumn style={{ width: "100%", position: "relative" }}>
      <div
        style={{
          ...styles.bigFont,
          fontSize: 40,
          width: "100%",
          // marginTop: 20,
          // marginBottom: 20,
          color: Palette.blue,
          display: "flex",
          maxWidth: "90%",
          // fontWeight: 600,
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <FormattedMessage defaultMessage="Informations de Livraison" />
        <Button size={"large"} onClick={onClose} style={{ position: "absolute", right: 0, top: 0, width: 40 }}>
          <MdClose size={30} />
        </Button>
      </div>
      <LineSeparator />
      <SpacerV size={20} />
      <div className="shopSliderAndLabel">
        <div className="shopLabel" style={{ minWidth: 100 }}>
          <FormattedMessage defaultMessage="Adresse" />
        </div>
        <FormControl fullWidth>
          <TextField
            rows={5}
            multiline={true}
            id="outlined-basic"
            color={"info"}
            variant="outlined"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </FormControl>
      </div>
      <div className="shopSliderAndLabel">
        <div className="shopLabel" style={{ minWidth: 100 }}>
          <FormattedMessage defaultMessage="Téléphone" />
        </div>
        <FormControl fullWidth>
          <TextField
            id="outlined-basic"
            color={"info"}
            variant="outlined"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </FormControl>
      </div>

      <SpacerV size={20} />
      <BigActionButton onClick={() => onSubmit(address, phone)}>
        <FormattedMessage defaultMessage="Finaliser la commande" />
      </BigActionButton>
    </FlexColumn>
  );
};

export const Shop = (params: { partyHoop?: boolean }) => {
  const [numberofhoops, setnumberofhoops] = useState(1);
  const [tubeDiameter, settubeDiameter] = useState(19);
  const [internalDiameter, setinternalDiameter] = useState(80);
  const [externalDiameter, setexternalDiameter] = useState(80 + (2 * tubeDiameter) / 10);
  const [shippingLocation, setshippingLocation] = useState<ShippingLocations>(ShippingLocations.MONDIALRELAY_COUNTRIES);
  const [promotionCode, setpromotionCode] = useState("");
  const [material, setMaterial] = useState("Polypro");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [diameterUnit, setDiameterUnit] = useState("cm");
  const [discount, setdiscount] = useState(0);
  const [odManuallySet, setodManuallySet] = useState(false);
  const [nBatteries, setNBatteries] = useState(1);
  const [ledDensity, setLedDensity] = useState(30);
  const [withGrip, setWithGrip] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(null as string | null);
  const modalCtx = useModal();
  const langCtx = useLanguageSelector();

  const auth = useAuth();

  const singleHoopPrice = () => {
    if (params.partyHoop) {
      const ledPrice = ledDensity === 60 ? 20 : 0;
      const batteryPrice = nBatteries === 4 ? 20 : 0;
      return 200 + ledPrice + batteryPrice;
    }

    return internalDiameter < 60 ? 400 : 450;
  };

  const computePrice = () => {
    return Math.round(numberofhoops * singleHoopPrice());
  };
  const computeDiscountRatio = (discountFromCode: number) => {
    const multipleHoopDiscount = params.partyHoop ? 0 : 5 * (numberofhoops - 1);
    return Math.min(0.3, (discountFromCode + multipleHoopDiscount) / 100);
  };
  const labelStyle: CSSProperties = {
    minWidth: 200,
    marginRight: 30,
    whiteSpace: "nowrap",
    textAlign: "right",
  };
  const sliderStyle = {
    sx: {
      "& .MuiSlider-valueLabel": {
        backgroundColor: "#660c737d",
      },
    },
  };

  const startAuthenticationInModal = useCallback(() => {
    modalCtx.setContent(<div id="firebaseui-auth-container"></div>);
    modalCtx.setVisible(true);
    return new Promise<boolean>((resolve, reject) => {
      authUIConfig.callbacks = {
        signInFailure: (err: any) => {
          // modalCtx.setVisible(false);
          modalCtx.setContent(
            <>
              <div>
                <FormattedMessage defaultMessage="Erreur de connexion, veuillez réessayer." />
              </div>
              <SpacerV size={30} />
              <BigActionButton onClick={() => modalCtx.setVisible(false)}>
                <FormattedMessage defaultMessage="Fermer" />
              </BigActionButton>
            </>
          );
          reject();
        },
        signInSuccessWithAuthResult: (auth: any, redirectUrl: any) => {
          // modalCtx.setVisible(false);
          resolve(true);
          return false;
        },
      };
      // setTimeout(() => getMyAuthUI().then(ui => ui.start("#firebaseui-auth-container", authUIConfig)), 0);
      setTimeout(() => getMyAuthUI().start("#firebaseui-auth-container", authUIConfig), 0);
    });
  }, [modalCtx]);

  const [checkPromoRunning, setCheckPromoRunning] = useState(false);

  const checkPromocodeDebounced = useCallback(
    debounce(async (code) => {
      // const code = e.target.value;
      try {
        if (code === "") return setdiscount(0);
        setCheckPromoRunning(true);
        const discount = await validatePromotionCode(code);
        //console.log("DISCOUNT: ", discount.data, code);
        setdiscount(discount.data);
      } catch (e) {
        console.log("Could not get discount: ", e);
        console.log("DISCOUNT: ", discount, code);
        setdiscount(0);
      } finally {
        setCheckPromoRunning(false);
      }
    }, 1000),
    []
  );
  const MINDIAMETER_CM = 50;
  const MAXDIAMETER_CM = 90;
  const MINDIAMETER_INCH = Math.round(0.3937 * 50);
  const MAXDIAMETER_INCH = Math.round(0.3937 * 90);
  const tubeDiameterInch = 0.3937 * tubeDiameter;
  const diameterUnitText = diameterUnit === "cm" ? "cm" : langCtx.language.startsWith("fr") ? "pouces" : "inches";
  const intl = useIntl();

  useEffect(() => {
    if (promotionCode != "") setCheckPromoRunning(true);
    checkPromocodeDebounced(promotionCode);
  }, [promotionCode]);

  // checkPromocodeDebounced(promotionCode);
  // const labelWidth = 200;
  const shippingCost = getHoopShippingCost(shippingLocation, numberofhoops);
  return (
    <FlexColumn id="shop" center style={{ width: "100%", marginTop: 100 }}>
      <SectionTitle>
        <BsCart size={50} style={{ marginRight: 10 }} />
        <FormattedMessage defaultMessage="Boutique" />
      </SectionTitle>

      <div
        style={{
          width: 500,
          maxWidth: "80vw",
          textAlign: "justify",
          textJustify: "inter-word",
        }}
      >
        <FormattedMessage
          defaultMessage="Nos cerceaux sont créés sur mesure et à la demande.
        Dès votre commande enregistrée, nous vous contacteront pour valider 
        vos options et vous envoyer un lien de paiement."
        />
        <br />
        <br />
        <FormattedMessage defaultMessage="Le prix inclut, pour chaque hoop:" />
        <br/><br/>
        <ul style={{ paddingLeft: 40 }}>
          <li>
            <FormattedMessage defaultMessage="Le cerceau" />
          </li>
          {!params.partyHoop && (
            <li>
              <FormattedMessage defaultMessage="4 piles et un chargeur USB 4 ports" />
            </li>
          )}
          {params.partyHoop && (
            <li>
              <FormattedMessage defaultMessage="La ou les piles et un chargeur USB 1 ou 4 ports (voir option)" />
            </li>
          )}
          <li>
            <FormattedMessage defaultMessage="Les frais de livraison en France, Belgique,  Luxembourg, Espagne, Allemagne, Autriche, Portugal, Italie et les Pays-Bas" />
          </li>
          <li>
            <FormattedMessage defaultMessage="La garantie 2 ans" />
          </li>
        </ul>
      </div>

      <SpacerV size={20} />
      {/* <Alert severity="info"
              style={{
                maxWidth: "80vw",
                width: 500,
                ...styles.normalFont,
                fontSize: 18,
              }}
            >
              <FormattedMessage defaultMessage="SUMMER DISCOUNT: obtenez <b>25%</b> de réduction avec le code
              <b>SUMMERHOOP23</b>. Offre valable sur les 50 premières commande de
              l'été." />
            </Alert> */}
      <SpacerV size={30} />
      <div className="shopSliderAndLabel">
        <div className="shopLabel">
          <FormattedMessage defaultMessage="Nombre de cerceaux" />
        </div>
        <Slider
          {...sliderStyle}
          className="shopSlider"
          color="secondary"
          // color={Palette.pink}
          // style={{ width: 300 }}
          marks
          min={1}
          max={8}
          value={numberofhoops}
          valueLabelDisplay="on"
          onChange={(e, value) => {
            setnumberofhoops(value as number);
          }}
        ></Slider>
      </div>
      {!params.partyHoop && (
        <div className="shopSliderAndLabel" style={{ height: 40, marginBottom: 0, marginTop: -30 }}>
          <div className="shopLabel"></div>
          <div style={{ width: "100%" }}>
            {numberofhoops > 1
              ? intl.formatMessage(
                  {
                    defaultMessage: "Réduction sur {numberofhoops} hoops: -{percent}%, (max 30%)",
                  },
                  {
                    numberofhoops,
                    percent: Math.min(30, (numberofhoops - 1) * 5),
                  }
                )
              : intl.formatMessage({
                  defaultMessage: "5% de réduction par cerceau supplémentaire, (max 30%)",
                })}
          </div>
        </div>
      )}

      <div className="shopSliderAndLabel">
        <div className="shopLabel">
          <FormattedMessage defaultMessage="Matière" />
        </div>

        <FormControl fullWidth>
          {/* <InputLabel id="tube-diameter">Tube diameter</InputLabel> */}
          <div>
            <FormattedMessage defaultMessage="Polypro transparent" />
          </div>
          {/* <Select
            style={{ backgroundColor: "#000a"}}
            value={material}
            onChange={(e) => {
              setMaterial(e.target.value as string);
            }}
          >
            <MenuItem value={"Polypro"}><FormattedMessage defaultMessage="Polypro transparent"/></MenuItem>
            <MenuItem value={"HDPE"}><FormattedMessage defaultMessage="HDPE white"/></MenuItem>
          </Select> */}
        </FormControl>
      </div>
      <div className="shopSliderAndLabel">
        <div className="shopLabel">Tube</div>

        <FormControl fullWidth>
          {/* <InputLabel id="tube-diameter">Tube diameter</InputLabel> */}
          <Select
            style={{ backgroundColor: "#000a" }}
            value={tubeDiameter}
            onChange={(e) => {
              const diam = e.target.value as number;
              settubeDiameter(diam);
              if (odManuallySet) {
                setinternalDiameter(externalDiameter - 0.2 * diam);
              } else setexternalDiameter(internalDiameter + 0.2 * diam);
            }}
          >
            <MenuItem value={16}>
              {" "}
              5/8 <FormattedMessage defaultMessage="pouces" />, 16mm{" "}
            </MenuItem>
            <MenuItem value={19}>
              {" "}
              3/4 <FormattedMessage defaultMessage="pouces" />, 19mm{" "}
              <FormattedMessage defaultMessage="(2.5x plus d'autonomie)" />
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <SpacerV size={20} />
      <div className="shopSliderAndLabel">
        <div className="shopLabel">
          <FormattedMessage defaultMessage="Unité" />
        </div>
        <div style={{ flexGrow: 1 }}>
          <RadioGroup
            row
            value={diameterUnit}
            onChange={(v) => {
              setDiameterUnit(v.target.value);
            }}
          >
            <FormControlLabel value={"cm"} control={<Radio />} label="cm" />
            <FormControlLabel
              value={"inch"}
              control={<Radio />}
              label={langCtx.language.startsWith("fr") ? "pouces" : "inches"}
            />
          </RadioGroup>
        </div>
      </div>

      {diameterUnit === "cm" && (
        <div className="shopSliderAndLabel">
          <div className="shopLabel">
            <FormattedMessage defaultMessage="Diamètre (cm)" />
          </div>
          <Slider
            {...sliderStyle}
            className="shopSlider"
            min={50}
            max={90}
            value={[internalDiameter, externalDiameter]}
            valueLabelDisplay="on"
            color="secondary"
            onChange={(e, value) => {
              const id = (value as number[])[0];
              const od = (value as number[])[1];
              if (id !== internalDiameter) {
                setinternalDiameter(id as number);
                setodManuallySet(false);
                setexternalDiameter((id as number) + 0.2 * tubeDiameter);
              } else {
                setinternalDiameter((od as number) - 0.2 * tubeDiameter);
                setodManuallySet(true);
                setexternalDiameter(od as number);
              }
            }}
          ></Slider>
        </div>
      )}

      {diameterUnit === "inch" && (
        <div className="shopSliderAndLabel">
          <div className="shopLabel">
            <FormattedMessage defaultMessage="Diamètre (pouces)" />
          </div>
          <Slider
            {...sliderStyle}
            className="shopSlider"
            min={MINDIAMETER_INCH}
            max={MAXDIAMETER_INCH}
            value={[round1(0.3937 * internalDiameter), round1(0.3937 * externalDiameter)]}
            valueLabelDisplay="on"
            color="secondary"
            onChange={(e, value) => {
              const isInternalChanging = 2.54 * (value as number[])[0] !== internalDiameter;

              const id = Math.min((value as number[])[0], MAXDIAMETER_INCH - 0.2 * tubeDiameterInch);
              const od = Math.max((value as number[])[1], MINDIAMETER_INCH + 0.2 * tubeDiameterInch);
              if (isInternalChanging) {
                setinternalDiameter(2.54 * id);
                setodManuallySet(false);
                setexternalDiameter(2.54 * id + 0.2 * tubeDiameter);
              } else {
                setinternalDiameter(2.54 * od - 0.2 * tubeDiameter);
                setodManuallySet(true);
                setexternalDiameter(2.54 * od);
              }
            }}
          />
        </div>
      )}

      <div className="shopSliderAndLabel">
        <div className="shopLabel"></div>
        <div style={{ flexGrow: 0 }}>
          <table
            style={{
              padding: 10,
              width: 350,
              backgroundColor: "#000a",
              borderWidth: 1,
              borderColor: "#333",
              borderStyle: "solid",
              borderRadius: 5,
            }}
          >
            <tr>
              <td style={{ paddingRight: 20 }}>
                <i>
                  <FormattedMessage defaultMessage="Diamètre interne" />
                </i>
              </td>
              <td>
                <span style={{ fontSize: 25 }}>
                  {round1(diameterUnit === "inch" ? 0.3937 * internalDiameter : internalDiameter)}
                </span>{" "}
                {diameterUnitText}
              </td>
            </tr>
            <tr>
              <td>
                <i>
                  <FormattedMessage defaultMessage="Diamètre externe" />
                </i>
              </td>
              <td>
                <span style={{ fontSize: 25 }}>
                  {round1(diameterUnit === "inch" ? 0.3937 * externalDiameter : externalDiameter)}
                </span>{" "}
                {diameterUnitText}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="shopSliderAndLabel" style={{ display: params.partyHoop ? undefined : "none" }}>
        <div className="shopLabel">
          <FormattedMessage defaultMessage="Leds" />
        </div>

        <FormControl fullWidth>
          {/* <InputLabel id="tube-diameter">Tube diameter</InputLabel> */}
          <Select
            style={{ backgroundColor: "#000a" }}
            value={ledDensity}
            onChange={(e) => {
              setLedDensity(e.target.value as number);
            }}
          >
            <MenuItem value={30}>standard (30 Leds/m)</MenuItem>
            <MenuItem value={60}>HD (60 Leds/m) +20€</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="shopSliderAndLabel" style={{ display: params.partyHoop ? undefined : "none" }}>
        <div className="shopLabel">
          <FormattedMessage defaultMessage="Batterie" />
        </div>

        <FormControl fullWidth>
          {/* <InputLabel id="tube-diameter">Tube diameter</InputLabel> */}
          <Select
            style={{ backgroundColor: "#000a" }}
            value={nBatteries}
            onChange={(e) => {
              setNBatteries(e.target.value as number);
            }}
          >
            <MenuItem value={1}>
              <FormattedMessage defaultMessage="1 batterie et un chargeur USB" />
            </MenuItem>
            <MenuItem value={4}>
              <FormattedMessage defaultMessage="4 batteries et un chargeur USB 4-ports +20€" />
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="shopSliderAndLabel">
        <div className="shopLabel">
          <FormattedMessage defaultMessage="Grip blanc" />
        </div>

        <FormControl fullWidth>
          {/* <InputLabel id="tube-diameter">Tube diameter</InputLabel> */}
          <div>
            <Checkbox
              onChange={(event) => {
                setWithGrip(event.target.checked);
              }}
              checked={withGrip}
            />
          </div>
        </FormControl>
      </div>

      {/* CODE PROMO  */}
      <div className="shopSliderAndLabel">
        <div className="shopLabel">
          <FormattedMessage defaultMessage="Code promo" />
        </div>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            position: "relative",
          }}
        >
          <TextField
            style={{ backgroundColor: "#000a" }}
            id="outlined-basic"
            color={promotionCode != "" ? (discount > 0 ? "success" : "error") : "info"}
            variant="outlined"
            onChange={(e) => {
              setdiscount(0);
              setpromotionCode(e.target.value);
              // checkPromocodeDebounced(e);
            }}
            focused
          />
          {checkPromoRunning ? (
            <CircularProgress size={20} style={{ position: "absolute", right: 10, top: 15 }} />
          ) : null}
        </div>
      </div>

      {/* SHIPPING LOCATION  */}
      <div className="shopSliderAndLabel">
        <div className="shopLabel">
          <FormattedMessage defaultMessage="Destination de livraison" />
        </div>

        <FormControl fullWidth>
          {/* <InputLabel id="tube-diameter">Tube diameter</InputLabel> */}
          <div style={{marginBottom: 10}}><FormattedMessage
                defaultMessage="Choisissez votre pays de livraison, nous ne livrons qu'en Europe."/></div>
          <Select
            style={{ backgroundColor: "#000a" }}
            value={shippingLocation}
            onChange={(e) => {
              setshippingLocation(e.target.value as ShippingLocations);
            }}
          >
            <MenuItem value={ShippingLocations.MONDIALRELAY_COUNTRIES}>
              <FormattedMessage
                defaultMessage="France, Espagne, Italie, Belgique,
            Luxembourg,  Pays-Bas, Allemagne, Autriche, Portugal"
              />
            </MenuItem>
            <MenuItem value={ShippingLocations.UE_UK_SWITZERLAND}>
              <FormattedMessage defaultMessage="Reste de l'UE, Royaume-Uni, Suisse" />
            </MenuItem>
            {/* <MenuItem value={ShippingLocations.WORLD}>
              <FormattedMessage defaultMessage="Reste du monde" />
            </MenuItem> */}
          </Select>
        </FormControl>
      </div>

      {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10 }}>
      
            <div style={labelStyle}>
            </div>
            <div style={{ display: "flex", }}>
              {discount > 0 ? <GiPartyPopper size={30} style={{ marginRight: 10 }} /> : <></>}
              {discount > 0 ? `Réduction de ${discount}% active` : ""}</div>
      
          </div> */}

      {/* PRICE / BUY  */}

      <FlexColumn
        style={{
          flexGrow: 1,
          marginTop: 20,
          justifyContent: "flex-end",
          alignItems: "flex-end",
          width: 800,
          maxWidth: "90vw",
          marginBottom: 150,
        }}
      >
        <FlexRow style={{ justifyContent: "flex-end" }}>
          <FormattedMessage defaultMessage="Sous-total " />
          <span style={{ width: 200, textAlign: "right" }}>
            {numberofhoops} x {singleHoopPrice()}€{/* {computePrice()}€ */} = {numberofhoops * singleHoopPrice()}€
          </span>
        </FlexRow>
        <SpacerV size={10} />
        <FlexRow
          style={{
            justifyContent: "flex-end",
            display: computeDiscountRatio(discount) > 0 ? "flex" : "none",
          }}
        >
          <FormattedMessage defaultMessage="Réduction" />
          <span style={{ width: 200, textAlign: "right" }}>-{Math.round(100 * computeDiscountRatio(discount))}%</span>
        </FlexRow>
        <SpacerV size={10} />
        <FlexRow style={{ justifyContent: "flex-end" }}>
          <FormattedMessage defaultMessage="Frais de port" />

          <span style={{ width: 200, textAlign: "right" }}>
            {shippingCost ? shippingCost + "€" : intl.formatMessage({ defaultMessage: "gratuits" })}
          </span>
        </FlexRow>
        {shippingLocation !== ShippingLocations.MONDIALRELAY_COUNTRIES && (
          <Alert severity="info" style={{ marginTop: 10, marginBottom: 10 }}>
            <FormattedMessage defaultMessage="Pour les pays en dehors de l'Union Européenne, des taxes d'import pourront être appliquées. Contactez les douanes de votre pays pour en savoir plus." />
          </Alert>
        )}
        <SpacerV size={10} />
        <LineSeparator style={{ width: 150 }} />
        <FlexRow style={{ fontSize: 40, marginTop: 10, justifyContent: "flex-end", alignItems: "center" }}>
          <span style={{ fontSize: 20 }}>
            Total (<FormattedMessage defaultMessage="TTC, TVA Non applicable" />)
          </span>
          <span style={{ width: 200, textAlign: "right" }}>
            {Math.round(computePrice() * (1 - computeDiscountRatio(discount))) + shippingCost}€
          </span>
        </FlexRow>
        {/* </FlexColumn> */}

        {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 60 }}> */}
        <div style={labelStyle}></div>

        <SpacerV size={40} />

        {/* PAYMENT METHOD  */}
        <FormControl fullWidth required error={paymentMethod === null}>
          <div className="shopSliderAndLabel">
            <div className="shopLabel" style={paymentMethod ? {} : { color: Palette.errorRed }}>
              <FormattedMessage defaultMessage="Mode de paiement*" />
            </div>

            {/* <InputLabel id="tube-diameter">Tube diameter</InputLabel> */}

            <FormControl fullWidth>

            <RadioGroup onChange={(v) => setPaymentMethod(v.target.value)}>
              <FormControlLabel
                value={PaymentMethods.BANK_TRANSFER}
                control={<Radio />}
                label={intl.formatMessage({ defaultMessage: "Virement bancaire (Moins de frais pour nous :)" })}
              />
              <FormControlLabel
                value={PaymentMethods.CB}
                control={<Radio />}
                label={intl.formatMessage({ defaultMessage: "Carte bleue" })}
              />
            </RadioGroup>
            </FormControl>
          </div>
        </FormControl>

        <div
          style={{
            flexGrow: 1,
            marginTop: 20,
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* STRIPE VERSION
          <BigActionButton onClick={async () => {
            console.log("CURRENT USER:", auth.currentUser ? auth.currentUser.email : "none");
            const order = {
              numberOfHoops: numberofhoops,
              material,
              tubeDiameter: tubeDiameter,
              hoopInternalDiameter: internalDiameter,
              promotionCode: promotionCode
            } as HoopOrder;
            // consol
            if (!auth.currentUser) {
              // save checkout in local storage.
              // Todo: Retry checkout after login.
              // localStorage.setItem("cart", JSON.stringify(order))
              console.log("before login:", auth.currentUser)
              await startAuthenticationInModal();
              console.log("After login:", auth.currentUser)
            }
  
            modalCtx.setContent(<><CircularProgress /><SpacerV size={20} /><div style={{ ...styles.bigFont }}>Préparation de votre commande</div></>);
            modalCtx.setVisible(true);
            // create stripe checkout
            try {
              // await new Promise(r => setTimeout(r, 2000));
              // throw new Error("test");
              const checkoutUrl = await checkoutHoopOrder(order);
              modalCtx.setVisible(false);
              // modalCtx.setVisible(false);
              // redirect to stripe checkout.
              window.location.assign(checkoutUrl.data);
            } catch (e) {
              modalCtx.setContent(<><MdError size={40} /><SpacerV size={20} /><div style={{ ...styles.bigFont }}>Erreur, veuillez retenter plus tard</div><SpacerV size={20} /><BigActionButton onClick={() => modalCtx.setVisible(false)}>Ok</BigActionButton></>);
  
  
            }
          }}>
            <BsCart />
            <span style={{ width: 10 }}></span>
            Acheter
          </BigActionButton>
           */}

          <BigActionButton
            onClick={async () => {
              // collect shipping data.
              try {
                if (!paymentMethod) {
                  modalCtx.setContent(
                    <div>
                      <div>
                        <FormattedMessage defaultMessage={"Veuillez choisir une méthode de paiement"} />
                      </div>
                      <SpacerV size={20} />
                      <BigActionButton onClick={() => modalCtx.setVisible(false)}>
                        <FormattedMessage defaultMessage="Ok" />
                      </BigActionButton>
                    </div>
                  );
                  modalCtx.setVisible(true);
                  return;
                }
                const shippingInfo = { address: "", phone: "" };
                await new Promise<void>((resolve, reject) => {
                  modalCtx.setContent(
                    <ShippingDetailModal
                      onClose={() => {
                        modalCtx.setVisible(false);
                        reject();
                      }}
                      onSubmit={(new_address, new_phone) => {
                        shippingInfo.address = new_address;
                        shippingInfo.phone = new_phone;
                        setAddress(new_address);
                        setPhone(new_phone);
                        resolve();
                      }}
                    />
                  );
                  modalCtx.setVisible(true);
                });

                // console.log(
                //   "CURRENT USER:",
                //   auth.currentUser ? auth.currentUser.email : "none"
                // );
                const order = {
                  numberOfHoops: numberofhoops,
                  material,
                  model: params.partyHoop ? HoopModel.PARTY : HoopModel.PERFORMER,
                  ...(params.partyHoop
                    ? {
                        options: {
                          nBatteries: nBatteries,
                          ledDensity: ledDensity,
                        },
                      }
                    : {}),
                  tubeDiameter: tubeDiameter,
                  hoopInternalDiameter: internalDiameter,
                  promotionCode: promotionCode,
                  grip: withGrip,
                  address: shippingInfo.address,
                  phone: shippingInfo.phone,
                  shippingCost: shippingCost,
                  language: langCtx.language,
                  diameterUnit: diameterUnit,
                  paymentMethod: paymentMethod,
                } as HoopOrder;
                // consol
                if (!auth?.currentUser) {
                  // save checkout in local storage.
                  // Todo: Retry checkout after login.
                  // localStorage.setItem("cart", JSON.stringify(order))
                  //console.log("before login:", auth.currentUser);
                  await startAuthenticationInModal();
                  //console.log("After login:", auth.currentUser);
                }
                modalCtx.setContent(
                  <>
                    <CircularProgress />
                    <SpacerV size={20} />
                    <div style={{ ...styles.bigFont }}>Préparation de votre commande</div>
                  </>
                );
                modalCtx.setVisible(true);
                // preorder hoop.
                try {
                  const checkoutUrl = await preorderHoop(order);
                  modalCtx.setContent(
                    <>
                      <MdCheck />
                      <SpacerV size={20} />
                      <div style={{ ...styles.bigFont, maxWidth: 500 }}>
                        <FormattedMessage
                          defaultMessage="Commande prise en compte. Vous allez bientôt recevoir un email de confirmation."
                        />
                      </div>
                      <SpacerV size={20} />
                      <BigActionButton onClick={() => modalCtx.setVisible(false)}>
                        <FormattedMessage defaultMessage="Fermer" />
                      </BigActionButton>
                    </>
                  );
                } catch (e) {
                  modalCtx.setContent(
                    <>
                      <MdError size={40} />
                      <SpacerV size={20} />
                      <div style={{ ...styles.bigFont }}>
                        <FormattedMessage defaultMessage="Erreur, veuillez retenter plus tard" />
                      </div>
                      <SpacerV size={20} />
                      <BigActionButton onClick={() => modalCtx.setVisible(false)}>Ok</BigActionButton>
                    </>
                  );
                }
              } catch {
                // Skip order validation.
                modalCtx.setVisible(false);
                //console.log("SKIPP ORDER!!!!!!!!!!!");
              }
            }}
          >
            <BsCart />
            <span style={{ width: 10 }}></span>
            <FormattedMessage defaultMessage="Commander" />
          </BigActionButton>
        </div>
      </FlexColumn>
    </FlexColumn>
  );
};
